import { post, put } from "../index";
import { ScrapbookApi } from "../partners";

export const addDateFetch = (relationshipId: string, dateRequest: {
    id: string,
    partnerId?: string,
    relationshipId?: string,
    userId?: string,
    rating: number,
    location: string,
    title?: string,
    date: string | null,
    status?: string,
    additionalInfo: {},
}, token: string | null = null) => {
    return post(ScrapbookApi + `/dates`, dateRequest, token);
}

export const updateDateFetch = (relationshipId: string, dateRequest: {
    userId: string | null;
    value: {
        id: string;
        date: Date | string | null;
    };
}, token: string | null = null) => {
    return put(ScrapbookApi + `/relationships/${relationshipId}/dates`, dateRequest, token);
}

export const suggestDatesFetch = (request: {
        userId: string,
        coords: {
            lat: number,
            lon: number
        },
        relationshipId: string,
}, token: string | null = null) => {
    return post(ScrapbookApi + `/dates/suggest`, request, token);
}
