import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'aws-amplify/auth/enable-oauth-listener';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Amplify } from "aws-amplify";

import './main.css';

export const UserPoolClientId = '5skj7i2gmj9b84lrsscu8euaf';
const redirectUrl = "https://littlescrapbook.com/auth";

if (redirectUrl === undefined) {
    throw new Error('REACT_APP_AUTH_REDIRECT_URL is not set');
} else {
    console.log(redirectUrl);
}

Amplify.configure({
    Auth: {
        Cognito: {
            loginWith: {
                    oauth: {
                        domain: 'scrapbook-auth.auth.ap-southeast-2.amazoncognito.com',
                        providers: ['Google'],
                        scopes: ['email', 'openid', 'profile'],
                        responseType: 'token',
                        redirectSignIn: [
                            redirectUrl,
                        ],
                        redirectSignOut: [
                            redirectUrl,
                        ],
                    }
            },
            userPoolId: 'ap-southeast-2_sj1d9d0ez',
            userPoolClientId: UserPoolClientId,
            identityPoolId: 'ap-southeast-2:5084a484-0e07-442c-8226-96058b0b7aaf',
            allowGuestAccess: true,
        }
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https:
