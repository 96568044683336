import { FunctionComponent, PropsWithChildren } from "react";
import { Button as MuiButton } from "@mui/material";

type Props = {
    children: string;
    onClick: () => void;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    secondary?: boolean;
};
export const Button: FunctionComponent<PropsWithChildren<Props>> = ({ children, onClick, secondary, disabled, type }) => {
    return (
        <MuiButton style={{
            maxWidth: '350px',
            maxHeight: '42px',
            width: '100%',
            backgroundColor: secondary ? '#fff' : '#b58fc0',
            color: secondary ? '#b58fc0' : '#fff',
        }}
                   variant="contained" onClick={onClick}>{children}</MuiButton>
    )
}
