import { createContext, FunctionComponent, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { decodeJWT } from "@aws-amplify/auth";
import { createUser } from "../fetch/user";
import { UserPoolClientId } from "../index";

export const logoutFunction = () => {
    localStorage.clear();
    window.location.href = '/login';
}

export const AuthorizationContext = createContext<{
    setToken: () => Promise<boolean>;
    authenticated: boolean;
    profile: Profile | null;
    logout: () => void;
    token: string | null;
}>({
    setToken: () => Promise.resolve(false),
    authenticated: false,
    profile: null,
    logout: () => {},
    token: null
});

type Profile = {
    id: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    lastName: string;
    complete: boolean;
};

export const AuthorizationProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
    const [profile, setProfile] = useState<Profile | null>(null);
    const [token, setToken] = useState<string | null>(null);

    const idTokenCallback = () => {
        console.log('ID Token Callback');
        const LastAuthUserKey = `CognitoIdentityServiceProvider.${UserPoolClientId}.LastAuthUser`;
        const LastAuthUser = localStorage.getItem(LastAuthUserKey);
        if (LastAuthUser != null) {
            const idTokenKey = `CognitoIdentityServiceProvider.${UserPoolClientId}.${LastAuthUser}.idToken`;
            return localStorage.getItem(idTokenKey);
        }
        return null;
    }

    useEffect(() => {
        setIdToken();
    }, []);

    // 'CognitoIdentityServiceProvider.3kdk20m7gc272vsjbncpful454.LastAuthUser'

    const setIdToken = async (): Promise<boolean> => {
        const token = idTokenCallback();
        if (token) {
            setToken(token);
            const decoded = decodeJWT(token).payload;
            const profile = {
                email: decoded.email,
                firstName: decoded.given_name,
                lastName: decoded.family_name,
                phoneNumber: decoded.phone_number || null,
                id: decoded['cognito:username'],
                complete: true
            } as Profile;
            await createUser({
                email: profile.email,
                name: profile.firstName,
                lastName: profile.lastName,
                phoneNumber: profile.email
            });
            setProfile(profile);
            return true;
        }
        return false;
    }

    const logout = useCallback(logoutFunction, [])

    return (
        <AuthorizationContext.Provider
        value={{
            profile,
            setToken: setIdToken,
            authenticated: !!token,
            logout,
            token
        }}>
        {children}
        </AuthorizationContext.Provider>
    );
}
