import { logoutFunction } from "../context/AuthorizationProvider";

export const FetchStorageAsyncKey = 'fetch-data';

export type FetchRequest<T> = {
    method: 'POST' | 'PUT';
    data: T;
    endpoint: string;
    timestamp: number;
};

function handleError<T>(
    error: any,
    data: T,
    endpoint: string,
    method: 'POST' | 'PUT',
    _resolve: (value: PromiseLike<Response> | Response) => void,
    reject: (reason?: any) => void,
) {
    console.log(error);
}

export const post = async <T>(endpoint: string, data: T, maybeToken: string | null = null): Promise<Response> => {
    let token: string | null = null;
    try {
        token = await localStorage.getItem('token') || maybeToken;
    } catch (e) {
        console.log('Error getting token', e);
    }
    console.log('POST', endpoint, token);
    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token ? token : ''}`,
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                console.log('Response', response);
                if (response.status === 401) {
                    logoutFunction();
                }
                resolve(response);
            })
            .catch(error => {
                console.log('error', error);
                handleError(error, data, endpoint, 'POST', resolve, reject);
            });
    });
};

function errorCallback<T>(
    data: T,
    endpoint: string,
    resolve: (value: PromiseLike<Response> | Response) => void,
    reject: (reason?: any) => void,
) {
    return (error: any) => {
        handleError(error, data, endpoint, 'PUT', resolve, reject);
    };
}

export const put = async <T>(endpoint: string, data: T, maybeToken: string | null = null): Promise<Response> => {
    console.log('PUT', endpoint);
    const token = maybeToken ?? localStorage.getItem('token');
    return new Promise<Response>((resolve, reject) => {
        fetch(endpoint, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                console.log(response);
                if (response.status === 401) {
                    logoutFunction();
                }
                resolve(response)
            })
            .catch(errorCallback(data, endpoint, resolve, reject));
    });
};

export const get = async (
    endpoint: string,
    options?: {
        headers: {
            Authorization: string;
        };
    },
): Promise<Response> => {
    console.log('GET', endpoint);
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...options?.headers,
        },
    }).then(response => {
        if (response.status === 401) {
            logoutFunction();
        }
        return response;
    }).catch(error => {
        console.log('error', error);
        throw error;
    });
};
