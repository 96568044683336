import { post } from "../index";
import { ScrapbookApi } from "../partners";

export const addLoveLanguageFetch = (userId: string, request: {
    loveLanguages: {
        capturedAt: string | Date,
        communication: number,
        gifts: number,
        id: string,
        partnerId: string,
        physicalTouch: number,
        qualityTime: number,
        support: number,
    }
}, token: string | null = null) => {

    return post(ScrapbookApi + '/users/' + userId + '/love-languages', request, token);
}
