
export function getLocation(): Promise<{lat: number; lon: number}> {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    window.location.href = "app-settings:location";
                } else {
                    navigator.geolocation.getCurrentPosition((location) => {
                        resolve({
                            lat: location.coords.latitude,
                            lon: location.coords.longitude
                    })}, () => {
                        window.location.href = "app-settings:location";
                    });
                }
            });
        } else {
            alert('Geolocation is not supported in your browser.');
        }
    })
}
