import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthorizationContext } from "../../context/AuthorizationProvider";
import { createUser } from "../../fetch/user";


export const Authorizer = () => {
    const { hash } = useLocation();
    const { setToken } = useContext(AuthorizationContext);

    useEffect(() => {
        setToken().then((result) => {
            if (result) {
                window.location.href = '/profile';
            }
        });
    }, [hash]);

    return null;
}
