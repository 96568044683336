import { post } from "../index";
import { ScrapbookApi } from "../partners";

export const addAttachmentStyleFetch = (body: {
    userId: string;
    relationshipId: string;
    result: {
        capturedAt: string;
        value: number;
    }
}, token: string | null = null) => {
    return post(ScrapbookApi + '/relationships/results/attachment-style', body, token);
}
