
export const ErrorPage = () => {
    return (
        <div style={{display:'flex', flex: 1, textAlign: 'center', justifyContent: 'center'}}>
            <p style={{flex: 1, marginTop: 'auto', marginBottom: 'auto', fontSize: 42, fontWeight: '600', color: '#505050'}}>
                404 😟
            </p>
        </div>
    )
}
