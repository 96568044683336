import { FunctionComponent, useContext, useMemo, useState } from "react";
import { getDateLocaleString } from "../../utils/dateUtils";
import { Grid, Typography } from "@mui/material";
import { StaticDatePicker } from "../StaticDatePicker";
import { RelationshipDate } from "../../modals/Partner";
import { updateDateFetch } from "../../fetch/dates";
import { AuthorizationContext } from "../../context/AuthorizationProvider";

export const PartnerDateCard: FunctionComponent<{date: RelationshipDate; relationshipId: string; userId: string}> = ({date, relationshipId, userId}) => {
    const [showModal, setShowModal] = useState(false);
    const token = useContext(AuthorizationContext).token;
    const [dateDate, setDateDate] = useState(date.date);

    const dateString = useMemo(() => {
        return getDateLocaleString(dateDate ?? undefined);
    }, [dateDate])

    const updateDate = async (newDate: Date) => {
        setDateDate(newDate)
        await updateDateFetch(relationshipId, {
            userId,
            value: {
                id: date.id!,
                date: newDate,
            }
        }, token);
    }

    return (
        <Grid item marginTop={2} style={{ backgroundColor: 'white', boxShadow: '2px 2px #c4c4c4' }} padding={2}
              direction={'column'} display={'flex'}>
            <Grid display={'flex'} direction={'row'} flex={1} justifyContent={'space-between'}>
                <Typography>{date.title}</Typography>
                {dateString !== '' ? (<Typography style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>{dateString}</Typography>) :
                    <Typography style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>Set Date</Typography>
                }
            </Grid>
            <StaticDatePicker value={dateDate} setValue={async (newDate) => {
                await updateDate(newDate);
            }} show={showModal} dismiss={() => setShowModal(false)} />
            <Grid item>
                <Typography>{date.location}</Typography>
            </Grid>
        </Grid>
    )
}
