import { Modal } from "@mui/material";
import { FunctionComponent } from "react";
import { StaticDatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const StaticDatePicker: FunctionComponent<{
    show: boolean;
    value: Date | string | null;
    setValue: (date: Date) => void;
    dismiss: () => void
}> = ({ show, dismiss, setValue, value }) => {
    return (
        <Modal
            open={show}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
            }}
            onClose={dismiss}>
            <MuiDatePicker
                defaultValue={dayjs(new Date())}
                onAccept={(newDate) => {
                    if (!newDate) return;
                    setValue(newDate.toDate());
                    dismiss();
                }}
                value={dayjs(value)}
                onClose={dismiss} />
        </Modal>
    );
}
