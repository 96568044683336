import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, IntroPage } from "./LoveLanguagePage";
import { LoveLanguageQuestions } from "./LoveLanguage/LoveLanguageQuestions";
import { PageContent } from "./PageContent";
import { PartnerContext } from "../context/PartnerContext";
import { Box, Grid, Typography } from "@mui/material";
import { Gauge } from "@mui/x-charts";
import { Button } from "../components/Buttons/Button";
import { addAttachmentStyleFetch } from "../fetch/attachmentStyle";
import { AuthorizationContext } from "../context/AuthorizationProvider";

const questions = require("./AttachmentStyle/partnerQuestions.json");

const PartnerAttachmentStyleResults: FunctionComponent<{ results: number[], pages: { title: string }[] }> = ({ results, pages }) => {
    const { token, profile } = useContext(AuthorizationContext);
    const { id: relationshipId } = useParams();
    const result = results.reduce((acc, value) => acc + value, 0) / results.length;
    const resultString = [
        "If your total score is in this range, it suggests that your partner is generally meeting your attachment style needs and providing you with a sense of security and fulfillment. This is often a positive sign of a healthy relationship.",
        "Scores in this range may indicate that there are some areas where your partner is satisfying your attachment style, but there could be room for improvement in certain aspects. It might be a good idea to communicate with your partner about your needs and work together to enhance your attachment satisfaction.",
        "Scores in this range might signal that there are significant gaps between your attachment style needs and what your partner is providing. This could potentially lead to feelings of dissatisfaction or discomfort. It's important to have open and honest communication with your partner to address these issues and find ways to bridge the gap."
    ];

    useEffect(() => {
        if (relationshipId && profile?.email && token) {
            addAttachmentStyleFetch({
                result: {
                    capturedAt: new Date().toISOString(),
                    value: result / 2
                },
                userId: profile?.email,
                relationshipId: relationshipId
            }, token);
        }
    }, [result, relationshipId, profile, token]);

    const navigate = useNavigate();

    return (
        <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Typography variant={'h4'} textAlign={'center'}>Results</Typography>
            <Grid container spacing={4}>
                <Grid item marginTop={4} display={'flex'} flex={1} alignItems={'center'}>
                    <Gauge
                        value={result}
                        startAngle={0}
                        endAngle={360}
                        width={120}
                        height={120}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} textAlign={'center'}>Your score is {result}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>{resultString[result < 30 ? 2 : result < 40 ? 1 : 0]}</Typography>
                </Grid>
            </Grid>
            <Box display={'flex'} flex={1} alignItems={'flex-end'} justifyContent={'center'}>
                <Button onClick={() => {
                    navigate('/partner/' + relationshipId);
                }}>Done</Button>
            </Box>
        </Box>
    )
}
export const PartnerAttachmentStylePage: FunctionComponent = () => {
    const { currentPartner } = useContext(PartnerContext);
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const { pages: questionnaire, options } = questions;
    const pages = useMemo(() => questionnaire.map((page: {title: string}) => {
        return {
            ...page,
            title: page.title.replace('{{name}}', currentPartner.name)
        }
    }).sort(() => Math.random() - 0.5), [questionnaire]);
    const [activeStep, setActiveStep] = useState(0);
    const [values, setValues] = useState<number[]>(new Array(pages.length).fill(50));
    const setValue = (index: number) => {
        return (value: number) => {
            setValues([...values.slice(0, index), value, ...values.slice(index + 1)]);
        }
    }

    const onNext = () => {
        if (activeStep === pages.length - 1) {
            setFinished(true);
            setStarted(false);
            return
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    return (
        <PageContent>
            <Card>
                {started ? <LoveLanguageQuestions
                        value={values[activeStep]}
                        activeStep={activeStep}
                        options={options}
                        total={pages.length}
                        page={pages[activeStep]}
                        setValue={setValue(activeStep)}
                        onNext={onNext} /> :
                    !finished ? <IntroPage title={'Compatibility Test'}
                                           content={'Understanding love language in your relationship can greatly enhance your relationship with {{name}} by helping you and {{name}} communicate and connect more effectively. This quick questionnaire will guide you in discovering {{name}} primary love language according to the 5 Love Languages'}

                                           setStarted={setStarted} /> : <PartnerAttachmentStyleResults results={values} pages={pages} />}
            </Card>
        </PageContent>
    )
}
