import * as dateFormat from 'dateformat';

const calculateTiming = (d: number) => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;
  while (d) {
    if (d >= 365) {
      years++;
      d -= 365;
    } else if (d >= 30) {
      months++;
      d -= 30;
    } else if (d >= 7) {
      weeks++;
      d -= 7;
    } else {
      days++;
      d--;
    }
  }
  return {
    years,
    months,
    weeks,
    days,
  };
};

export const getYearFromDate = (date: Date): number => {
  return new Date(date).getFullYear();
};

export const getDateString = (date?: string | Date): string => {
  if (date === undefined) {
    return '';
  } else if (typeof date === 'string') {
    return dateFormat.default(date, 'dd-mm-yyyy');
  } else {
    return dateFormat.default(date, 'dd-mm-yyyy');
  }
};

export const getDateLocaleString = (date?: string | Date | null): string => {
  if (date === undefined || date === null) {
    return '';
  }
  if (typeof date === 'string') {
    date = getDateFromString(date);
  }
  const currentYear = new Date().getFullYear();
  if (date?.getFullYear() === currentYear) {
    return dateFormat.default(date, 'dd mmm');
  } else {
    return dateFormat.default(date, 'dd mmm yyyy');
  }
};

export const getDateFromString = (date?: string | null): Date | undefined => {
  if (!date) {
    return undefined;
  }
  function stringToDate() {
    const dateSplitted = date?.split('-');
    if (dateSplitted) {
      return new Date(+dateSplitted[2], +dateSplitted[1] - 1, +dateSplitted[0]);
    } else {
      return undefined;
    }
  }

  if (date?.match(/^(\d){2}-(\d){2}-(\d){4}$/)) {
    return stringToDate();
  }
  try {
    const res = new Date(date as string);
    if (!res || isNaN(res.getTime())) {
      return stringToDate();
    }
    return res;
  } catch {
    return stringToDate();
  }
};
