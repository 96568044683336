
abstract class Synchronise {
    id?: string;
    synced?: boolean;
}

export enum PartnerAttachmentStyleSatisfactory {
    GOOD,
    MID,
    BAD,
}

export type DateAdditionalInfo = {
    cost?: string;
    paidPercentage?: number;
    notes?: string;
};

export type PartnerForm = {
    name?: string;
    type?: string;
    duration?: number;
    durationUnit?: number;
    satisfaction?: number;
    endDate?: Date;
    startDate?: Date;
    inProgress?: boolean;
};

export enum HappinessKey {
    COMMUNICATION = 'Communication',
    SUPPORT = 'Support',
    QUALITY_TIME = 'Quality time',
    PHYSICAL_TOUCH = 'Physical touch',
    GIFTS = 'Receiving gifts',
}

export type Happiness = {
    communication: number;
    support: number;
    qualityTime: number;
    physicalTouch: number;
    gifts: number;
    capturedAt: Date | undefined;
    partnerId: string;
    id: string;
};

export type AttachmentStyleResult = {
    value: number;
    capturedAt: Date;
};

export enum RelativeDate {
    daily = 'daily',
    weekly = 'weekly',
    fortnightly = 'fortnightly',
    monthly = 'monthly',
    yearly = 'yearly',
    one_off = 'one-off',
    ongoing = 'ongoing',
}

export type Reminder = {
    id: string;
    type: string;
    title: string;
    frequency: RelativeDate;
    lastCompletedAt: Date | null;
    description: string;
    date?: Date;
};

export enum Answer {
    YES = 'YES',
    NO = 'NO',
    MAYBE = 'N/A',
}

export type PartnerAnswer = {
    capturedAt: number;
    answer: Answer;
    userId: string;
    question: string;
    relationshipId?: string;
};

export type RelationshipDate = {
    id?: string;
    title?: string;
    location: string;
    date: string | Date | null;
}

type RelationshipStatus = 'PENDING' | 'CONFIRMED' | "SINGLE";
export enum RelationshipType {
    Fling = '2',
    Dating = '5',
    Relationship = '1',
    Complicated = '9',
    Unsure = '6',
    Engaged = '7',
    Married = '8',
    OpenRelationship = '10',
}

export type RelationshipPartner = {
    userId: string;
    name: string;
    id: string;
    nickName?: string;
    origin?: string;
};
type UserSurveyResult<T> = {
    userId: string;
    values: T[]
}

export type LoveLanguageResult = {
    capturedAt: string | Date;
    communication: number;
    gifts: number;
    id: string;
    partnerId: string;
    physicalTouch: number;
    qualityTime: number;
    support: number;
}

type IncludeOptions = {
    userId: string;
    value: boolean;
}

type RelationshipAnswer = {
    capturedAt: number;
    question: string;
    answer: string;
};

export type LitePartner = {
    name: string,
    userId: string,
    id?: string,
    origin?: string;
    nickName?: string;
}

export type RelationshipEvent = {
    comment: string;
    date: string;
    userId: string;
    id: "UPDATE_TYPE" | "END_RELATIONSHIP";
}

export type Relationship = {
    id: string;
    status: RelationshipStatus;
    startDate: string | Date;
    type: string;
    inProgress: boolean;
    relationshipType: RelationshipType;
    partners: RelationshipPartner[];
    attachmentStyles: UserSurveyResult<AttachmentStyleResult>[];
    loveLanguages: UserSurveyResult<LoveLanguageResult>[];
    dates: RelationshipDate[];
    gallery: UserSurveyResult<string>[];
    includeAttachmentStyle: IncludeOptions[];
    includeDates: IncludeOptions[];
    includeGallery: IncludeOptions[];
    includeLoveLanguage: IncludeOptions[];
    partnersList?: LitePartner[];
    events: RelationshipEvent[];
    endDate?: Date;
    answers?: UserSurveyResult<RelationshipAnswer>[]
}

export const getPartnerFromRelationship = (relationship: Relationship, myUserId?: string): RelationshipPartner => {
    return relationship.partnersList?.find(partner => partner.userId !== myUserId) as RelationshipPartner;
}
