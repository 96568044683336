import React, { FunctionComponent, useContext, useEffect } from "react";
import {
    Authenticator,
} from '@aws-amplify/ui-react';
import { AuthorizationContext } from "../context/AuthorizationProvider";
import { AuthUser } from "@aws-amplify/auth";

const formFields = {
    signIn: {
        username: {
            label: 'Email:',
            placeholder: 'Enter your Email',
            order: 2,
        },
        password: {
            label: 'Password:',
            placeholder: 'Enter your Password',
            order: 3,
        }
    },
    signUp: {
        username: {
            label: 'Email:',
            placeholder: 'Enter your Email',
            order: 1,
        },
        email: {
            label: 'Confirm your email:',
            placeholder: 'Re-enter your Email',
            order: 2,
        },
        password: {
            label: 'Password:',
            placeholder: 'Enter your Password',
            order: 3,
        },
        confirm_password: {
            label: 'Confirm Password:',
            order: 4,
        },
        given_name: {
            label: "Name",
            order: 5
        }
    },
};

const SignUpCallback: FunctionComponent<{user: AuthUser | undefined}> = ({user}) => {
    const {setToken} = useContext(AuthorizationContext);

    useEffect(() => {
        if (user != null) {
            setToken().then((result) => {
                if (result) {
                    window.location.href = '/profile';
                }
            });
        }
    }, [user]);

    return (
        <div></div>
    )
}
export const SignUpPage = () => {
    window.addEventListener('storage', () => {
        console.log('Storage updated');
    });


    return (
        <div style={{maxWidth: 500, alignSelf: 'center'}}>
            <Authenticator formFields={formFields} socialProviders={["google"]}>
                {({  user }) => (
                    <SignUpCallback user={user} />
                )}
            </Authenticator>
        </div>
    )
};
