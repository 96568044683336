import { Box, Modal, Typography } from "@mui/material";
import { Loader } from "../Loader";
import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { getLocation } from "../../utils/location";
import { useParams } from "react-router-dom";
import { AuthorizationContext } from "../../context/AuthorizationProvider";
import { addDateFetch, suggestDatesFetch } from "../../fetch/dates";
import { Button } from "../Buttons/Button";
import { v4 as uuidv4 } from "uuid";

type SuggestionsData = {
    data: Suggestion[];
}
export type Suggestion = {
    duration: string;
    title: string;
    cost: number;
    location: string;
    notes: string;
    websiteUrl: string;
};

export const SuggestedDatesModal: FunctionComponent<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const { id: relationshipId } = useParams<{ id: string }>();
    const { profile, token } = useContext(AuthorizationContext);
    const [suggestions, setSuggestions] = useState<SuggestionsData | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getLocation().then(location => {
            if (isOpen && relationshipId && profile?.email && token && location) {
                suggestDatesFetch({
                    userId: profile?.email,
                    coords: location,
                    relationshipId,
                }, token).then(response => {
                    response.json().then((data: string) => {
                        setSuggestions(JSON.parse(data));
                    });
                    setLoading(false);
                }).catch(() => {
                    setLoading(false);
                });
            }
        });
    }, [relationshipId, profile?.email, token, isOpen]);

    return (
        <Modal
            open={isOpen}
            style={{ display: 'flex', justifyContent: 'center' }}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box alignSelf={'center'}
                 display={'flex'}
                 height={'90vh'}
                 padding={2}
                 width={'420px'}
                 style={{
                     backgroundColor: 'white',
                     flexDirection: 'column',
                     overflow: 'scroll',
                 }}>
                <Typography id="modal-modal-title" variant="h4" textAlign={'center'}>
                    Suggested Dates
                </Typography>
                {loading && <Box alignSelf={'center'} marginTop={'auto'} marginBottom={'auto'}>
                    <Loader />
                </Box>}
                {suggestions != null && suggestions.data.map((suggestion, index) => (
                    <Box key={index} display={'flex'} flexDirection={'column'} padding={2} style={{ borderBottom: '1px solid #e0e0e0' }}>
                        <Typography variant={'h6'}>{suggestion.title}</Typography>
                        <Typography variant={'body1'}>{suggestion.location}</Typography>
                        <Typography variant={'body2'}>{suggestion.duration}</Typography>
                        <Typography variant={'body2'}>{suggestion.cost}</Typography>
                        <Typography variant={'body2'}>{suggestion.notes}</Typography>
                        <Typography variant={'body2'}>{suggestion.websiteUrl}</Typography>
                        <Box marginTop={2}>
                            <Button onClick={async () => {
                                await addDateFetch(relationshipId!, {
                                    id: uuidv4(),
                                    userId: profile?.email,
                                    partnerId: profile?.email,
                                    relationshipId: relationshipId,
                                    rating: 0,
                                    location: suggestion.location,
                                    title: suggestion.title,
                                    date: null,
                                    additionalInfo: {},
                                }, token)
                            }}>Add to Dates</Button>
                        </Box>
                    </Box>))}
            </Box>
        </Modal>
    );
}
