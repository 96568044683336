import { FunctionComponent, PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";

export const PageContent: FunctionComponent<PropsWithChildren<{title?: string, centered?: boolean}>> = ({title, centered = false, children}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ecd5f6',
            width: '100%',
            height: '100%',
            paddingBottom: '40px',
            justifyContent: centered ? 'center' : 'normal',
            alignItems: centered ? 'center' : 'initial',
        }}>
            {title != null && (<Grid container style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                opacity: 1,
                alignItems: 'center',
                backgroundColor: 'white',
                paddingTop: 10,
                paddingBottom: 10,
                zIndex: 1,
                width: '100%',
                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
            }}>
                <Typography variant="h6" style={{fontWeight: 'bold'}}>{title}</Typography>
            </Grid>)}
            <div style={{
                paddingRight: '40px',
                paddingLeft: '40px',
            }}>
                {children}
            </div>
        </div>
    );
}
