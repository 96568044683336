import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { addLoveLanguageFetch } from "../fetch/loveLanguage";
import { AuthorizationContext } from "./AuthorizationProvider";
import { v4 as uuidv4 } from 'uuid';
import { getUser, updateProfile } from "../fetch/user";
import { AttachmentStyles, LoveLanguages } from "../pages/ProfilePage";


type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

const entries = <Obj,>(obj: Obj) => Object.entries(obj as ArrayLike<Obj>) as Entries<Obj>

export const mapRecordToMap = <MyString extends string>(
    data: Record<MyString, number>) =>
    entries(data).reduce((acc, [key, value]) => {
        acc.set(key, value);
        return acc
    }, new Map<MyString, number>());

export const ProfileContext = createContext<{
    loveLanguageResults: Map<LoveLanguages, number> | null;
    attachmentStyle: 'anxious' | 'secure' | 'avoidant' | 'mixed' | null;
    addLoveLanguageResults: (loveLanguageResults: Map<LoveLanguages, number>) => void;
    addAttachmentStyleResults: (attachmentStyleResults: Map<string, number>) => void;
}>({
    loveLanguageResults: null,
    attachmentStyle: null,
    addLoveLanguageResults: (_: Map<LoveLanguages, number>) => { },
    addAttachmentStyleResults: (_: Map<string, number>) => { }
});

export const ProfileContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [loveLanguageResults, setLoveLanguageResults] = useState<Map<LoveLanguages, number> | null>(null);
    const [attachmentStyle, setAttachmentStyle] = useState<'anxious' | 'secure' | 'avoidant' | 'mixed' | null>(null);
    const [attachmentStyleResults, setAttachmentStyleResults] = useState<Map<string, number>>(new Map());
    const {profile, token} = useContext(AuthorizationContext);

    useEffect(() => {
        if (profile?.email != null && token != null) {
            getUser(profile?.email!, token).then(user => {
                if (user?.loveLanguages != null && user.loveLanguages.length > 0) {
                    const lastLoveLanguageResult = user.loveLanguages[user.loveLanguages.length - 1];
                    const loveLanguageResults = new Map<LoveLanguages, number>();
                    loveLanguageResults.set('physical-touch', lastLoveLanguageResult.physicalTouch);
                    loveLanguageResults.set('quality-time', lastLoveLanguageResult.qualityTime);
                    loveLanguageResults.set('words-of-affirmation', lastLoveLanguageResult.communication);
                    loveLanguageResults.set('gifts', lastLoveLanguageResult.gifts);
                    loveLanguageResults.set('acts-of-service', lastLoveLanguageResult.support);
                    setLoveLanguageResults(loveLanguageResults);
                }
                if (user?.attachmentStyleValues != null && user.attachmentStyleValues.length > 0) {
                    const lastAttachmentStyleResult = user.attachmentStyleValues[user.attachmentStyleValues.length - 1];
                    setAttachmentStyleResults(mapRecordToMap(lastAttachmentStyleResult));
                    if (user.attachmentStyles) {
                        setAttachmentStyle(user.attachmentStyles as AttachmentStyles)
                    }
                }
            })
        }
    }, [profile?.email, token]);

    function getAttachmentStyle(score: number) {
        if (score >= 30 && score <= 50) {
            return 'anxious';
        } else if (score >= 15 && score <= 29) {
            return 'secure';
        } else if (score >= 10 && score <= 14) {
            return 'avoidant';
        } else {
            return 'mixed';
        }
    }

    const addAttachmentStyleResults = (attachmentStyleResults: Map<string, number>) => {
        setAttachmentStyleResults(attachmentStyleResults);
        const score = [...attachmentStyleResults.values()].reduce((a, b) => a + b);
        const style = getAttachmentStyle(score);
        setAttachmentStyle(style);
        updateProfile({
            phoneNumber: profile?.email,
            attachmentStyle: style,
            attachmentStyleValues: Array.from(attachmentStyleResults.entries()).map(([key, value]) => ({ [key]: value })),
        }, token);
    }

    const addLoveLanguageResults = (loveLanguageResults: Map<LoveLanguages, number>) => {
        setLoveLanguageResults(loveLanguageResults);
        addLoveLanguageFetch(profile?.email!, {
            loveLanguages: {
                capturedAt: new Date(),
                physicalTouch: loveLanguageResults.get('physical-touch')!,
                qualityTime: loveLanguageResults.get('quality-time')!,
                communication: loveLanguageResults.get('words-of-affirmation')!,
                gifts: loveLanguageResults.get('gifts')!,
                support: loveLanguageResults.get('acts-of-service')!,
                partnerId: '',
                id: uuidv4()
            }
        }, token);
    }

    return (
        <ProfileContext.Provider
            value={{
                loveLanguageResults,
                addLoveLanguageResults,
                attachmentStyle,
                addAttachmentStyleResults
            }}>
            {children}
        </ProfileContext.Provider>
    )
}
