import React, { FunctionComponent, useState } from "react";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { Button } from "../../components/Buttons/Button";

export const LoveLanguageQuestions: FunctionComponent<{
    page: {
        title: string;
        description?: string;
        example?: string;
    };
    total: number;
    value: number;
    options: {
        label: string
    }[];
    activeStep: number;
    onNext: () => void;
    setValue: (value: number) => void;
}> = ({ page, options, activeStep, onNext, setValue, value, total }) => {
    const [s, sValue] = useState(value)
    return (
        <>
            <div style={{ marginBottom: 10 }}>
                {activeStep + 1} / {total}
            </div>
            <Typography fontSize={22} flex={1} textAlign={'center'}>{page.title}</Typography>
            <Typography flex={1} marginTop={10} fontWeight={200}>
                {options[Math.floor(Math.min(value, 99) / 20)].label}</Typography>
            <div style={{ flex: 2, width: '100%' }}>
                <Box gap={2}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Slider aria-label="Volume" value={s} onChange={(val: Event) => {
                            const value = (val.target as unknown as {value: number}).value;
                            if (value) {
                                setValue(value)
                                sValue(value);
                            }
                        }} />
                    </Stack>
                </Box>
            </div>
            <Box flex={2} justifyContent={'center'} alignItems={'center'} marginTop={5}>
                <Typography fontSize={14} flex={1} textAlign={'center'}>{page.description ?? page.example}</Typography>
            </Box>
            <Box flex={1} marginTop={5} alignItems={'center'}>
                <Button onClick={() => {
                    onNext();
                    sValue(50);
                }}>Next</Button>
            </Box>
        </>
    )
}
