import { v4 as uuidv4 } from 'uuid';
import { RelativeDate, Reminder } from "../modals/Partner";

export class AnniversaryCalculator {
  private startDate: Date;

  constructor(startDate: Date) {
    this.startDate = startDate;
  }

  public upcomingAnniversaries(numAnniversaries: number): Reminder[] {
    const anniversaryList: Reminder[] = [];
    const currentDate: Date = new Date();

    let anniversaryDate = this.getAnniversaryDate(currentDate);

    // Find the next 'numAnniversaries' upcoming anniversaries
    while (anniversaryList.length < numAnniversaries) {
      const monthsDiff = AnniversaryCalculator.monthDiff(
        this.startDate,
        anniversaryDate,
      );
      const yearsDiff = Math.floor(monthsDiff / 12);

      if (yearsDiff > 0 || (yearsDiff === 0 && monthsDiff > 0)) {
        const anniversaryType =
          yearsDiff > 0
            ? `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} anniversary`
            : monthsDiff > 0
            ? `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} anniversary`
            : '';

        anniversaryList.push({
          date: anniversaryDate,
          title: anniversaryType,
          id: uuidv4(),
          lastCompletedAt: null,
          frequency: RelativeDate.one_off,
          description: '',
          type: 'ANNIVERSARY',
        });
        // Calculate the next anniversary date
        anniversaryDate = new Date(
          AnniversaryCalculator.getYear(anniversaryDate, yearsDiff, monthsDiff),
          AnniversaryCalculator.getMonth(
            anniversaryDate,
            monthsDiff,
            yearsDiff,
          ),
          this.startDate.getDate(),
        );
      }
    }

    return anniversaryList;
  }

  private getAnniversaryDate(currentDate: Date): Date {
    if (this.startDate.getFullYear() < currentDate.getFullYear()) {
      if (
        this.startDate.getMonth() > currentDate.getMonth() ||
        (this.startDate.getMonth() === currentDate.getMonth() &&
          this.startDate.getDate() > currentDate.getDate())
      ) {
        return new Date(
          currentDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate(),
        );
      } else {
        return new Date(
          currentDate.getFullYear() + 1,
          this.startDate.getMonth(),
          this.startDate.getDate(),
        );
      }
    } else {
      if (this.startDate.getDate() > currentDate.getDate()) {
        return new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          this.startDate.getDate(),
        );
      } else {
        return new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          this.startDate.getDate(),
        );
      }
    }
  }

  private static getMonth(
    anniversaryDate: Date,
    monthsDiff: number,
    yearsDiff: number,
  ) {
    if (monthsDiff > 0 && yearsDiff === 0) {
      return anniversaryDate.getMonth() + 1;
    } else {
      return anniversaryDate.getMonth();
    }
  }

  private static getYear(
    anniversaryDate: Date,
    yearsDiff: number,
    monthsDiff: number,
  ): number {
    if (yearsDiff > 0 || monthsDiff === 0 || monthsDiff >= 12) {
      return anniversaryDate.getFullYear() + 1;
    } else {
      return anniversaryDate.getFullYear();
    }
  }

  private static monthDiff(d1: Date, d2: Date): number {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
}
