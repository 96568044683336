import {get, post} from '../index';
import { ScrapbookApi } from "../partners";

export type BeginVerificationRequest = {
  deviceId: string;
  phoneNumber: string;
};

export type FacebookRegisterRequest = {
  deviceId: string;
  name: string;
  id: string;
};

type VerifyCodeRequest = BeginVerificationRequest & {
  code: string;
};

type UpdateUserRequest = {
  phoneNumber?: string;
  name?: string;
  firebaseToken?: string;
  attachmentStyle?: string;
  attachmentStyleValues?: {[key: string]: number}[];
  paidUser?: boolean;
  subscriptionProduct?: string;
  subscriptionReceipt?: string;
};

export const createUser = (request: {
  phoneNumber: string;
  firebaseToken?: string;
  name?: string;
  lastName?: string;
  email?: string;
}) => {
    return post(ScrapbookApi + '/users/register', request);
}
export const beginVerification = (
  request: BeginVerificationRequest,
): Promise<Response> => {
  return post<BeginVerificationRequest>(
    ScrapbookApi + '/begin-verification',
    request,
  );
};

export const verifyCode = (request: VerifyCodeRequest) => {
  return post<VerifyCodeRequest>(ScrapbookApi + '/verify-code', request);
};

export const updateProfile = (
  request: UpdateUserRequest,
  token: string | null = null,
): Promise<Response> => {
  return post<UpdateUserRequest>(ScrapbookApi + '/users', request, token);
};

export const getUser = async (id: string, maybeToken: string | null = null): Promise<User | undefined> => {
  const path = ScrapbookApi + '/users/' + id;
  const response = await get(path, {
    headers: {
      Authorization: 'Bearer ' + (maybeToken ?? localStorage.getItem('token')),
    }
  });
  return await response.json();
};

export const deleteStoredUser = (userId: string) => {
  return fetch(ScrapbookApi + '/users/' + userId, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const addLoveLanguagesResult = (userId: string, loveLanguages: any) => {
  return post(ScrapbookApi + '/users/' + userId + '/love-languages', {
    loveLanguages,
  });
};

export const socialProviderRegister = (request: FacebookRegisterRequest) => {
  return post<FacebookRegisterRequest>(
    ScrapbookApi + '/facebook-register',
    request,
  );
};

export type User = {
  phoneNumber: string;
  userName?: string;
  name: string | undefined;
  email?: string;
  password?: string;
  id: string;
  completed: boolean;
  deviceId: string | undefined;
  expirationTime: number | undefined;
  birthDate: string | undefined;
  loginMethod: 'phone' | 'facebook' | undefined | 'email';
  firebaseToken: string | undefined;
  loveLanguages?: LoveLanguageResult[];
  attachmentStyles?: string;
  attachmentStyleValues?: Record<string, number>[];
  relationships?: string[];
  paidUser?: boolean;
  subscriptionReceipt?: string;
  subscriptionProduct?: string;
}

export type LoveLanguageResult = {
  capturedAt: string | Date;
  communication: number;
  gifts: number;
  id: string;
  partnerId: string;
  physicalTouch: number;
  qualityTime: number;
  support: number;
}
