import { PageContent } from "./PageContent";
import { useNavigate, useParams } from "react-router-dom";
import { FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { getRelationship, getRelationshipScore } from "../fetch/partners";
import { AuthorizationContext } from "../context/AuthorizationProvider";
import { AttachmentStyleResult, getPartnerFromRelationship, Relationship } from "../modals/Partner";
import { Box, Grid, Typography } from "@mui/material";
import { Button } from "../components/Buttons/Button";
import { Gauge, GaugeContainer, GaugeReferenceArc, GaugeValueArc, useGaugeState } from "@mui/x-charts";
import { LoveLanguageResult } from "../fetch/user";
import { PartnerContext } from "../context/PartnerContext";
import { getDateFromString, getDateLocaleString } from "../utils/dateUtils";
import { Loader } from "../components/Loader";
import { SuggestedDatesModal } from "../components/SuggestedDatesModal";
import { AnniversaryCalculator } from "../utils/AnniversaryCalculator";
import { StaticDatePicker } from "../components/StaticDatePicker";
import { PartnerDateCard } from "../components/PartnerDateCard";

function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
        // No value to display
        return null;
    }

    const target = {
        x: cx + outerRadius * Math.sin(valueAngle),
        y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
        <g>
            <circle cx={cx} cy={cy} r={5} fill="red" />
            <path
                d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
                stroke="red"
                strokeWidth={3}
            />
        </g>
    );
}

const AttachmentStyleCompatibility: FunctionComponent<{
    partner: { name: string; id: string } | null;
    relationshipId?: string;
    result?: AttachmentStyleResult[]
}> = ({ partner, relationshipId, result }) => {
    const navigate = useNavigate();
    const lastResult = useMemo(() => {
        if (result) {
            return result[result.length - 1];
        }
        return null;
    }, [result]);

    return (
        <>
            <Typography marginTop={4}>{!lastResult ? `Take your Attachment Style quiz first to find out how it gets satisfied in the relationship
                with ${partner?.name}` : `See how your attachment style has been met in your relationship with ${partner?.name}`}</Typography>
            <Grid container spacing={3} display={'flex'} direction={'column'} marginTop={0}>
                {lastResult && <Grid item display={'flex'}>
                    <Gauge
                        value={lastResult.value * 2}
                        startAngle={0}
                        endAngle={360}
                        width={120}
                        height={120}
                    />
                </Grid>}
                <Grid item display={'flex'} justifyContent={'center'}>
                    <Button onClick={() => {
                        navigate(`/partner/${relationshipId}/${partner?.id}/attachment-style`)
                    }}>Compatibility Test</Button>
                </Grid>
            </Grid>
        </>
    )
}

const LoveLanguageCompatibility: FunctionComponent<PropsWithChildren<{
    relationshipId?: string;
    partner?: { name: string; id: string } | null;
    loveLanguageResults?: LoveLanguageResult[]
}>> = ({ children, partner, relationshipId, loveLanguageResults }) => {
    const navigate = useNavigate();
    const latestResult = useMemo(() => {
        if (loveLanguageResults) {
            return loveLanguageResults[loveLanguageResults.length - 1];
        }
        return null;
    }, [loveLanguageResults]);
    return (
        <>
            <Typography marginTop={4}>{
                loveLanguageResults == null ?
                    `Take your first love language quiz to compare your love language to ${partner?.name}` :
                    `Take a look how your love languages are being met with your relationship with ${partner?.name}`}</Typography>
            <Grid container spacing={4} display={'flex'} direction={'column'} marginTop={1}>
                {loveLanguageResults != null && (
                    <Grid item display={'flex'} flexDirection={'column'}>
                        {latestResult != null && (
                            <>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={16} variant={'button'}>Words of Affirmation</Typography>
                                    <Typography variant={'h5'}>{latestResult.communication}</Typography>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={16} variant={'button'}>Physical Touch</Typography>
                                    <Typography variant={'h5'}>{latestResult.physicalTouch}</Typography>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={16} variant={'button'}>Quality Time</Typography>
                                    <Typography variant={'h5'}>{latestResult.qualityTime}</Typography>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={16} variant={'button'}>Acts of Service</Typography>
                                    <Typography variant={'h5'}>{latestResult.support}</Typography>
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography fontSize={16} variant={'button'}>Gifts</Typography>
                                    <Typography variant={'h5'}>{latestResult.gifts}</Typography>
                                </Box>
                            </>

                        )}
                    </Grid>
                )}
                <Grid item display={'flex'} justifyContent={'center'}>
                    {loveLanguageResults != null ?
                        <Button onClick={() => {
                            navigate(`/partner/${relationshipId}/${partner?.id}/love-language`)
                        }}>Compatibility Test</Button> :
                        <Button onClick={() => {
                            navigate(`/love-language`)
                        }}>Love Language Test</Button>}
                </Grid>
            </Grid>
        </>
    )
}

export const PartnerPage = () => {
    const { id } = useParams();
    const { token, profile } = useContext(AuthorizationContext);
    const { setCurrentPartner } = useContext(PartnerContext);
    const [relationship, setRelationship] = useState<Relationship | null>(null);
    const [score, setScore] = useState<number>(0);
    const navigate = useNavigate();
    const [suggestionsOpened, setSuggestionsOpened] = useState(false);
    const partner = useMemo(() => {
        if (relationship) {
            const partner = getPartnerFromRelationship(relationship, profile?.email);
            setCurrentPartner(partner);
            return partner;
        } else return null;
    }, [relationship, profile?.email])
    const [loading, setLoading] = useState(true);

    const reminders = useMemo(() => {
        if (relationship?.startDate) {
            return new AnniversaryCalculator(getDateFromString(relationship?.startDate.toString())!).upcomingAnniversaries(2);

        }
    }, [relationship]);

    useEffect(() => {
        if (id && token) {
            getRelationship(id, token).then((relationship) => {
                setRelationship(relationship);
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            });
            getRelationshipScore(id, token).then((score) => {
                setScore(score.score);
            });
        }
    }, [id]);

    const dates = useMemo(() => {
        if (relationship?.dates) {
            return relationship.dates?.sort((a, b) => {
                if (a.date && b.date) {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                }
                return 0;
            })
        }
        return [];
    }, [relationship?.dates])

    return (
        <PageContent title={partner?.name}>
            {loading && <Loader />}
            <Grid container spacing={4} paddingTop={5}>
                <Grid item md={6} xs={12} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {score > 0 && <Box
                        display={'flex'}
                        flexDirection={'column'}
                        marginTop={4}
                        height={280} width={'100%'} alignSelf={'center'}>
                        <Typography textAlign={'left'} variant={'h6'}>Relationship Score</Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <GaugeContainer
                                width={240}
                                height={240}
                                startAngle={-110}
                                endAngle={110}
                                value={score}>
                                <GaugeReferenceArc />
                                <GaugeValueArc />
                                <GaugePointer />
                            </GaugeContainer>
                        </Box>
                    </Box>}
                    {relationship && (
                        <Grid md={12} display={'flex'} direction={'column'} container spacing={4}>
                            <Grid item display={'flex'}>
                                <Typography textAlign={'left'} variant={'h6'}>Upcoming Events</Typography>
                            </Grid>
                            <Grid item display={'flex'} justifyContent={'center'}>
                                <Grid container direction={'column'}>
                                    {reminders?.map((reminder) => {
                                        const dateString = getDateLocaleString(reminder.date ?? undefined);
                                        return (
                                            <Grid item marginTop={2} style={{ backgroundColor: 'white', boxShadow: '2px 2px #c4c4c4' }} padding={2}
                                                  direction={'column'} display={'flex'}>
                                                <Grid display={'flex'} direction={'row'} flex={1} justifyContent={'space-between'}>
                                                    <Typography>{reminder.title}</Typography>
                                                    {dateString !== '' ? <Typography style={{ cursor: 'pointer' }}>{dateString}</Typography> :
                                                        <Typography style={{ cursor: 'pointer' }}>Set Date</Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item display={'flex'}>
                                <Typography textAlign={'left'} variant={'h6'}>Upcoming Dates</Typography>
                            </Grid>
                            <Grid item display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                                <Grid container direction={'column'}>
                                    {dates.map((date) => {
                                        return <PartnerDateCard date={date} relationshipId={relationship.id} userId={profile?.email!} />
                                    })}
                                </Grid>
                                {relationship.dates == null && <Typography textAlign={'center'} variant={'caption'}>No upcoming dates</Typography>}
                            </Grid>
                            <Grid item justifyContent={'center'} display={'flex'}>
                                <Button secondary={true} onClick={() => {
                                    setSuggestionsOpened(true);
                                }}>Suggest Dates</Button>
                                <SuggestedDatesModal isOpen={suggestionsOpened} onClose={() => setSuggestionsOpened(false)} />
                            </Grid>
                            <Grid item justifyContent={'center'} display={'flex'}>
                                <Button onClick={() => {
                                    navigate(`/partner/${id}/dates`)
                                }}>Add a Date</Button>
                            </Grid>
                        </Grid>)}
                </Grid>
                {relationship && <Grid item md={6} xs={12} spacing={4}>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Typography variant={'h6'}>Love Language Compatibility</Typography>
                            <LoveLanguageCompatibility partner={partner}
                                                       loveLanguageResults={relationship?.loveLanguages ? relationship.loveLanguages.find(a => a.userId === profile?.email)?.values : undefined}
                                                       relationshipId={relationship?.id} />
                        </Grid>
                        <Grid item>
                            <Typography variant={'h6'}>Attachment Styles Compatibility</Typography>
                            <AttachmentStyleCompatibility partner={partner} relationshipId={relationship?.id}
                                                          result={relationship?.attachmentStyles ? relationship.attachmentStyles.find(a => a.userId === profile?.email)?.values : undefined} />
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
        </PageContent>
    )
}
