import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useState } from "react";
import { addPartnerFetch } from "../fetch/partners";
import { PageContent } from "./PageContent";
import { Button } from "../components/Buttons/Button";
import { AuthorizationContext } from "../context/AuthorizationProvider";
import * as profileLottie from "./profile.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router-dom";

const typeValues: Record<string, string> = {
    "1": "Relationship",
    "2": "Fling",
    "5": "Dating",
    "6": "Unsure",
    "7": "Engaged",
    "8": "Married",
    "9": "It's Complicated",
    "10": "Open Relationship"
};

export const AddPartnerPage = () => {
    const { profile, token } = useContext(AuthorizationContext);
    const [type, setType] = useState<string>('');
    const [inProgress, setInProgress] = useState(true);
    const [name, setName] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const navigate = useNavigate();

    return (
        <PageContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', minWidth: '400px' }}>
                <Typography variant="h5" component="h5" textAlign={'center'}>
                    Add a partner
                </Typography>
                <Player src={profileLottie} autoplay loop style={{ height: '200px', width: '200px' }} />
                <div style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: '300px'
                }}>
                    <div style={{ marginTop: 20 }}>
                        <TextField style={{ width: '100%', backgroundColor: 'white', borderRadius: 4 }}
                                   label="Name"
                                   variant="outlined"
                                   color={'secondary'}
                                   onChange={(val) => {
                                       setName(val.target.value)
                                   }} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Relationship Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                style={{ width: '100%', backgroundColor: 'white', borderRadius: 4 }}
                                id="demo-simple-select"
                                value={type}
                                color={'secondary'}
                                label="type"
                                onChange={(val) => {
                                    setType(val.target.value as string)
                                }}
                            >
                                {Object.keys(typeValues).map((key) => {
                                    return <MenuItem key={key} value={key}>{typeValues[key]}</MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{
                        marginTop: 20,
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: '4px'
                    }}>
                        <DatePicker
                            className={'date-picker'}
                            onChange={(val) => {
                                if (val === null) return;
                                setStartDate(val.toDate())
                            }} />
                    </div>
                    <div style={{ marginTop: 20, flexDirection: 'row' }}>
                        <Typography style={{ flex: 1 }} variant="subtitle1">Is this partner still in your life?</Typography>
                        <Switch
                            color={'secondary'}
                            value={inProgress}
                            onChange={(val) => {
                                setInProgress(val.target.checked)
                            }} style={{ flex: 1, color: '#b58fc0' }} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Button onClick={async () => {
                            const partner = await addPartnerFetch({
                                    name: name,
                                    type: type,
                                    id: profile?.id + '-random-' + startDate?.getTime(),
                                    startDate: startDate?.toString() ?? '',
                                    inProgress: inProgress,
                                    userId: '',
                                    satisfaction: 0,
                                }, profile!.email,
                                token!);
                            const result = await partner.json();
                            navigate(`/partner/${result.id}`)
                        }}>Add Partner</Button>
                    </div>
                </div>
            </div>
        </PageContent>
    )
}
