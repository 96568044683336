import { get, post } from '../index';
import { Relationship, RelationshipPartner } from "../../modals/Partner";

export const ScrapbookApi = 'https://d7dbmdzyac.execute-api.ap-southeast-2.amazonaws.com/prod';

export type Partner = {
    userId: string;
    startDate: string;
    inProgress: boolean;
    id: string;
    name: string;
    type: string;
    origin?: string;
    nickName?: string;
    satisfaction?: number;
}

export const addPartnerFetch = async (
    partner: Partner,
    userId: string,
    token: string,
): Promise<Response> => {
    return post(ScrapbookApi + '/relationships', {
        partner: {
            ...partner,
        },
        userId: userId,
        includeDates: true,
        includeGallery: true,
        includeLoveLanguage: true,
        includeAttachmentStyle: true,
        dates: [],
        gallery: [],
        loveLanguage: [],
        attachmentStyle: [],
    }, token);
};

export const getAllPartners = async (userId: string, token: string): Promise<{name: string; relationshipId: string}[]> => {
    const response = await get(ScrapbookApi + `/users/${userId}/relationships`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return await response.json();
}

export const getRelationship = async (relationshipId: string, token: string): Promise<Relationship> => {
    const response = await get(ScrapbookApi + `/relationships/${relationshipId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return await response.json();
}

export const getRelationshipScore = async (relationshipId: string, token: string): Promise<{ score: number }> => {
    const response = await get(ScrapbookApi + `/relationships/${relationshipId}/score`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return await response.json();
}

export const addResults = <T>(
    userId: string,
    relationshipId: string,
    result: T,
    token: string | null = null,
) => {
    return post(ScrapbookApi + '/relationships/results/love-languages', {
        userId: userId,
        relationshipId: relationshipId,
        result: result,
    }, token);
};
