import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { RelationshipPartner } from "../modals/Partner";
import { getAllPartners } from "../fetch/partners";
import { AuthorizationContext } from "./AuthorizationProvider";

type Partner = { name: string; relationshipId: string };
export const PartnerContext = createContext<{
    currentPartner: RelationshipPartner,
    setCurrentPartner: (partner: RelationshipPartner) => void,
    partners: Partner[]
}>({
    currentPartner: {} as RelationshipPartner,
    setCurrentPartner: () => {},
    partners: []
})

export const PartnerContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [currentPartner, setCurrentPartner] = useState<RelationshipPartner>({} as RelationshipPartner);
    const { authenticated, profile, token, logout } = useContext(AuthorizationContext);

    const [partners, setPartners] = useState([] as Partner[]);

    useEffect(() => {
        if (authenticated && profile?.email && token) {
            getAllPartners(profile.email, token).then((relationships) => {
                setPartners(relationships);
            });
        }
    }, [authenticated, profile?.email, token]);

    return (
        <PartnerContext.Provider value={{currentPartner, setCurrentPartner, partners}}>
            {children}
        </PartnerContext.Provider>
    )
}
