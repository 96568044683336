import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { PageContent } from "./PageContent";
import { LoveLanguageQuestions } from "./LoveLanguage/LoveLanguageQuestions";
import { Card, IntroPage } from "./LoveLanguagePage";
import { LoveLanguages } from "./ProfilePage";
import { ProfileContext } from "../context/ProfileContext";
import { Box, Typography } from "@mui/material";
import { Button } from "../components/Buttons/Button";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { addResults } from "../fetch/partners";
import { AuthorizationContext } from "../context/AuthorizationProvider";
const partnerPages = require('./LoveLanguage/partnerPages.json');

export function getLoveLanguageMatch(loveLanguageResults: Map<LoveLanguages, number> | null): { key: LoveLanguages; value: number }[] {
    return getLoveLanguages(loveLanguageResults);

}
function getLoveLanguages(loveLanguageResults: Map<LoveLanguages, number> | null): { key: LoveLanguages; value: number }[] {
    const values = [
        {
            key: 'words-of-affirmation' as LoveLanguages,
            value: loveLanguageResults?.get('words-of-affirmation') || 0
        },
        {
            key: 'gifts' as LoveLanguages,
            value: loveLanguageResults?.get('gifts') || 0
        },
        {
            key: 'acts-of-service' as LoveLanguages,
            value: loveLanguageResults?.get('acts-of-service') || 0
        },
        {
            key: 'quality-time' as LoveLanguages,
            value: loveLanguageResults?.get('quality-time') || 0
        },
        {
            key: 'physical-touch' as LoveLanguages,
            value: loveLanguageResults?.get('physical-touch') || 0
        }
    ];
    return values.sort((a, b) => b.value - a.value);
}

const PartnerLoveLanguageResults: FunctionComponent<{
    pages: {
        label: string;
    }[], results: Map<LoveLanguages, number>
}> = ({ pages, results }) => {
    const {id, partnerId} = useParams();
    const { profile, token } = useContext(AuthorizationContext);
    function getResultValue(key: LoveLanguages) {
        const nrOfQuestions = pages.filter(page => page.label === key).length;
        const totalPossible = nrOfQuestions * 100;
        return Math.floor(results.get(key)! / totalPossible * 100);
    }

    useEffect(() => {
        const happiness = {
            id: uuidv4(),
            partnerId,
            gifts: getResultValue('gifts'),
            communication: getResultValue('words-of-affirmation'),
            physicalTouch: getResultValue('physical-touch'),
            qualityTime: getResultValue('quality-time'),
            support: getResultValue('acts-of-service'),
            capturedAt: new Date(),
        };
        if (profile?.email && id) {
            addResults(profile.email, id, happiness, token).then(() => {
                console.log('results added');
            });
        }
    }, [results, profile?.email, token]);

    const navigate = useNavigate();
    const physicalTouchResult = getResultValue('physical-touch');
    const qualityTimeResult = getResultValue('quality-time');
    const actsOfServiceResult = getResultValue('acts-of-service');
    const wordsOfAffirmationResult = getResultValue('words-of-affirmation');
    const giftsResult = getResultValue('gifts');



    return (
        <Box flex={1} display={'flex'} flexDirection={'column'}>
            <Typography variant={'h4'}>Compatibility Results</Typography>
            <Box marginTop={6} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                <Typography flex={2}>Physical Touch:</Typography>
                <Typography flex={1}>{physicalTouchResult}%</Typography>
            </Box>
            <Box marginTop={4} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                <Typography flex={2}>Quality Time:</Typography>
                <Typography flex={1}>{qualityTimeResult}%</Typography>
            </Box>
            <Box marginTop={4} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                <Typography flex={2}>Acts of Service:</Typography>
                <Typography flex={1}>{actsOfServiceResult}%</Typography>
            </Box>
            <Box marginTop={4} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                <Typography flex={2}>Words of Affirmation:</Typography>
                <Typography flex={1}>{wordsOfAffirmationResult}%</Typography>
            </Box>
            <Box marginTop={4} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                <Typography flex={2}>Gifts:</Typography>
                <Typography flex={1}>{giftsResult}%</Typography>
            </Box>
            <Box marginTop={6} justifyContent={'center'} display={'flex'}>
                <Typography textAlign={'left'}>
                    Remember, this questionnaire provides only a rough idea of your relationship's attachment style and should not be considered a definitive assessment. If you're interested in exploring your attachment style further, consider seeking guidance from a mental health professional.
                </Typography>
            </Box>
            <Box display={'flex'} alignItems={'flex-end'} flex={1}>
                <Button onClick={() => {
                    navigate('/profile')
                }}>Done</Button>
            </Box>
        </Box>
    )
}

export const PartnerLoveLanguagePage: FunctionComponent = () => {
    const { loveLanguageResults } = useContext(ProfileContext)
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const { pages: questionnaire, options } = partnerPages;
    const [activeStep, setActiveStep] = useState(0);

    const resultRef = useRef(new Map<LoveLanguages, number>());
    const pages = useMemo(() => {
        const loveLanguages: { key: LoveLanguages; value: number }[] = getLoveLanguages(loveLanguageResults);
        // get 5 questions for the most popular love language, 4 for the second most popular, 3 for the third, 2 for the fourth, and 1 for the least popular
        const res = loveLanguages.flatMap((loveLanguage, index) => {
            return questionnaire.filter((question: { label: string }) => question.label === loveLanguage.key).slice(0, 5 - index);
        });
        return res.sort(() => Math.random() - 0.5)
    }, [questionnaire, loveLanguageResults]);
    const [values, setValues] = useState<number[]>(new Array(pages.length).fill(50));

    const setValue = (index: number) => {
        return (value: number) => {
            setValues([...values.slice(0, index), value, ...values.slice(index + 1)]);
        }
    }

    const onNext = () => {
        const resultMap = resultRef.current;
        if (resultMap.get(pages[activeStep].label as LoveLanguages) != null) {
            resultMap.set(pages[activeStep].label as LoveLanguages, values[activeStep] + resultMap.get(pages[activeStep].label as LoveLanguages)!);
        } else {
            resultMap.set(pages[activeStep].label as LoveLanguages, values[activeStep]);
        }
        if (activeStep === pages.length - 1) {
            setFinished(true);
            setStarted(false);
            return
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    return (
        <PageContent>
            <Card>
                {started ? <LoveLanguageQuestions
                        value={values[activeStep]}
                        activeStep={activeStep}
                        options={options}
                        total={pages.length}
                        page={pages[activeStep]}
                        setValue={setValue(activeStep)}
                        onNext={onNext} /> :
                    !finished ? <IntroPage title={'Compatibility Test'}
                                           content={'Understanding love language in your relationship can greatly enhance your relationship with {{name}} by helping you and {{name}} communicate and connect more effectively. This quick questionnaire will guide you in discovering {{name}} primary love language according to the 5 Love Languages'}

                                           setStarted={setStarted} /> : <PartnerLoveLanguageResults results={resultRef.current} pages={pages} />}
            </Card>
        </PageContent>
    );
}
